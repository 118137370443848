import React from 'react';
import './waterdrop.styles.scss';

const waterDrop = () => {
    return (
        <>
            <div id="drop-container" className="drop-container">
                <div id="Drop" className="drop">
                </div>
            </div>
        </>
    )
}

export default waterDrop;